import React from 'react';

import {
  ILeaveChallengeModalContext,
  LeaveChallengeModalContext,
  ShowModalParams,
} from './LeaveChallengeModalContext';
import { LeaveChallengeModal } from '../../components-shared/LeaveChallengeModal';
import { LeaveSessionModal } from '../../components-shared/LeaveSessionModal/LeaveSessionModal';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface ILeaveChallengeModalProviderProps
  extends ILeaveChallengeModalContext {}

export enum LeaveModalType {
  LeaveChallenge = 'LeaveChallenge',
  LeaveSession = 'LeaveSession',
}

export const LeaveChallengeModalProvider: FCWithChildren<
  ILeaveChallengeModalProviderProps
> = (props) => {
  const [opened, setOpened] = React.useState<LeaveModalType>(null);
  const [participantId, setParticipantId] = React.useState<string>(null);
  const [onClose, setOnClose] = React.useState<() => void>(() => () => {});
  const [onLeave, setOnLeave] = React.useState<() => void>(() => () => {});
  const [challengeId, setChallengeId] = React.useState<string>(null);

  return (
    <LeaveChallengeModalContext.Provider
      value={{
        showModal: (opts: ShowModalParams, _onClose, _onLeave) => {
          setOpened(opts.modalType || LeaveModalType.LeaveChallenge);
          setParticipantId(opts.participantId);
          setOnClose(() => () => {
            if (_onClose) {
              _onClose();
            }
            setOpened(null);
          });
          setOnLeave(() => () => {
            if (_onLeave) {
              _onLeave();
            }
            setOpened(null);
          });
          opts.challengeId && setChallengeId(opts.challengeId);
        },
      }}
    >
      <LeaveChallengeModal
        isOpen={opened === LeaveModalType.LeaveChallenge}
        participantId={participantId}
        challengeId={challengeId}
        onClose={onClose}
        onLeave={onLeave}
      />
      <LeaveSessionModal
        opened={opened === LeaveModalType.LeaveSession}
        onLeave={onLeave}
        onClose={onClose}
        onCancel={onClose}
      />
      {props.children}
    </LeaveChallengeModalContext.Provider>
  );
};
